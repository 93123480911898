<template>
  <div>
    <ProviderGameList />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  page: {
    title: 'Game settings',
  },
  components: {
    ProviderGameList: () => import('./components/ProviderGameList.vue'),
  },
  data() {
    return {
      selectedGameType: '',
    }
  },
  computed: {
    ...mapGetters(['gameTypes']),
    filteredGameTypes() {
      return this.gameTypes || []
    },
  },
  watch: {
    filteredGameTypes(val) {
      if (val.length > 0 && !this.selectedGameType) {
        this.selectedGameType = val[0].type
      }
    },
  },
  async created() {
    await this.fetchProviderGames()
    if (this.filteredGameTypes.length > 0) {
      this.selectedGameType = this.filteredGameTypes[0].type
    }
  },
  methods: {
    ...mapActions(['fetchProviderGames']),
  },
}
</script>
